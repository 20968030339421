@font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/Graphik-Regular-Web.woff') format('woff'),
        url('./assets/fonts/Graphik-Regular-Web.woff2') format('woff2');
    font-weight: normal;
}
@font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/Graphik-Bold-Web.woff') format('woff'),
        url('./assets/fonts/Graphik-Bold-Web.woff2') format('woff2');
    font-weight: bold;
}
@font-face {
    font-family: 'Graphik';
    src: url('./assets/fonts/Graphik-Medium-Web.woff') format('woff'),
        url('./assets/fonts/Graphik-Medium-Web.woff2') format('woff2');
    font-weight: 500;
}

body {
    margin: 0;
    font-family: 'Graphik', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body {
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-image: url('./assets/SeedPaper.jpg');
    background-size: cover;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
